<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="selectedSpeciality"
    v-model:search-input="searchInput"
    class="select-speciality"
    :items="groupedSpecialities"
    :loading="loading"
    item-title="name"
    item-value="id"
    clear-on-select
    data-cy="selectSpeciality"
  />
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [String, Array],
      default: null,
    },
    firstItems: {
      type: Array,
      default: null,
    },
    firstItemsName: {
      type: String,
      default: null,
    },
    hideOthers: {
      type: Boolean,
    },
    name: {
      type: String,
      default: null,
    },
    fieldName: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue', 'update:name'],
  data() {
    return {
      specialities: [],
      loading: false,
      searchInput: null,
    }
  },
  computed: {
    selectedSpeciality: {
      get() {
        if (this.fieldName && this.modelValue) {
          return this.modelValue.map((val) => val[this.fieldName])
        } else {
          return this.modelValue
        }
      },
      set(val) {
        if (this.fieldName && val) {
          const input = val.map((val) => {
            return {
              [this.fieldName]: val,
            }
          })
          this.$emit('update:modelValue', input)
        } else {
          this.$emit('update:modelValue', val)
        }
        if (typeof this.modelValue === 'string') {
          const speciality = this.specialities.find((spec) => spec.id === val)
          this.$emit('update:name', speciality ? speciality.name : null)
        }
        this.searchInput = null
      },
    },
    groupedSpecialities() {
      let specialities = []
      if (!this.hideOthers) {
        specialities = JSON.parse(JSON.stringify(this.specialities))
      }
      if (this.firstItems && this.firstItems.length) {
        specialities.unshift({ divider: true })
        specialities.unshift(
          ...this.firstItems.map((item) => this.specialities.find((spec) => spec.id === item))
        )
        if (this.firstItemsName && this.firstItemsName.length && !this.hideOthers) {
          specialities.unshift({ header: this.firstItemsName })
        }
      }
      return specialities
    },
  },
  async mounted() {
    this.loading = true
    await this.$cms
      .request(
        this.$readItems('speciality', {
          filter: {
            profession: {
              _eq: 'PHYSICIAN',
            },
          },
          fields: ['id', 'name'],
          sort: ['name'],
        })
      )
      .then((response) => {
        this.specialities = response
        this.loading = false
      })
  },
  methods: {},
}
</script>
<style lang="scss">
.v-subheader {
  color: #2f523e !important;
}
</style>
